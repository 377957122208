<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Order</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="mobile">Mobile No</label>
                                                <input type="text" id="mobile" v-model="search.mobile" placeholder="Enter Mobile No" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group" style="margin-top:28px;">
                                                <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData" ><i class="fa fa-search"></i></button>
                                                <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            </div>
                                        </div>
                                    </div>prin
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm text-center"> 
                                            <thead>
                                                <tr class="text-center">
                                                    <th style="width:8%">Action</th>
                                                    <th style="width:12%">Order ID</th>
                                                    <th style="width:10%">Merchant</th>
                                                    <th style="width:12%">Customer</th>
                                                    <th style="width:14%">Address</th>
                                                    <th style="width:10%">Status</th>  
                                                    <th style="width:10%">Destination</th>
                                                    <th style="width:6%">Order Date</th>
                                                    <th style="width:6%">Price</th> 
                                                    <th style="width:12%">Instruction</th>                                
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index.id">
                                                    <td style="width:8%">
                                                        <button class="btn btn-success btn-sm mr-2" @click="printPos(item)">
                                                            POS
                                                        </button>
                                                        <button class="btn btn-warning btn-sm mr-2" @click="printLabel(item)">
                                                            Label
                                                        </button>
                                                    </td>
                                                    <td style="width:12%">{{ item.tracking_id }}</td>
                                                    <td style="width:10%" @click="editModal(key, order)">{{ item.merchant.business }}</td>
                                                    <td style="width:12%">{{ item.name }}, {{ item.mobile }}{{ item.mobile_2 ? ', '+ item.mobile_2 : '' }}</td>
                                                    <td style="width:14%">{{ item.address }}</td>
                                                    <td class="text-center"><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>                                    
                                                    <td class="text-center">{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td style="width:6%">{{ item.date }}</td>
                                                    <td style="width: 6%">{{ item.price }}</td>
                                                    <td style="width:12%">{{ item.instruction }}</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>
<script>

import config from '@/config'
import Pos from './Pos.js'
import Label from './Label.js'

export default {
    name:'Bank',
    data(){
        return {
            loader: false,
            search: {
                mobile: ''
            },
            listData:[],
            printOrders:[],
        }
    },
    created(){
        this.loadData()
    },
    computed : {
        statusList () {
            return this.$store.state.statusList 
        }
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData(){     
            this.loader = true 
            const response = await config.getData('/order/requesting', this.search)
            this.loader = false
            if (response.status == 200){                
                this.listData = response.data
            } else {
                this.listData = []
            } 
        },
        getStatus (status) {
            const tmpStatus = this.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        },
        getBase64Logo (logo) {
            var canvas = document.createElement('canvas')
            canvas.width = logo.width
            canvas.height = logo.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(logo, 0, 0)
            var dataURL = canvas.toDataURL('image/png')
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
        },
        printPos(item) {
            this.printOrders = []
            this.printOrders.push(item)
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            Pos.expostPosDetails(base64Logo, this.printOrders)
        },
        printLabel(item) {
            this.printOrders = []
            this.printOrders.push(item)
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            Label.exportLabelDetails(base64Logo, this.printOrders)
        },
        reload () {
            this.search.mobile = ''
        }
    }
}
</script>